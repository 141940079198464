var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"panel-categories"},[_c('div',{class:[
        'border h-100 w-100 justify-content-center align-items-center',
        !_vm.loading ? 'd-none' : 'd-flex',
      ]},[_c('b-spinner',{attrs:{"variant":"warning","label":"Spinning"}})],1),(!_vm.isRefresh)?_c('div',{class:[
        'w-100  border border-right-0 justify-content-center ',
        _vm.loading ? 'd-none' : 'd-md-flex',
      ]},_vm._l((_vm.maxLv),function(item,index){return _c('Category',{key:index,attrs:{"index":index,"list":_vm.levelList[`lv${item}List`],"selected":_vm.list,"arrow":_vm.maxLv != item ? true : false,"maxLv":_vm.maxLv,"createIndex":_vm.createIndex,"hasCreateBtn":_vm.hasCreateBtn},on:{"handleSelect":_vm.handleSelect,"handleCreate":_vm.handleCreate}})}),1):_vm._e()]),(_vm.v && _vm.v.$error)?_c('div',[(_vm.v.required == false)?_c('span',{staticClass:"text-danger"},[_vm._v("กรุณาเลือกหมวดหมู่")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }